<script lang="ts">
    import {onMount} from 'svelte';
    import TopAppBar, {
      Row,
      Section,
      Title,
      AutoAdjust
    } from '@smui/top-app-bar';
    import IconButton, {Icon} from '@smui/icon-button';
    import Menu from '@smui/menu';
    import type { MenuComponentDev } from '@smui/menu';
    import List, { Item, Separator, Text } from '@smui/list';
    import { userInfo } from '../stores/stores';
    import { loadAll } from "@square/svelte-store";
    import { app } from '$lib/firebase';
    import { getAnalytics, logEvent } from "firebase/analytics";
    import { goto } from '$app/navigation';
    import '@smui/layout-grid';
    import { auth } from '$lib/auth';
    import {
        mdiMenu, mdiLogin, mdiLogout, mdiAccount, mdiWeatherSunny, mdiWeatherNight, 
        mdiInformationOutline, mdiAccountPlusOutline, mdiAccountCircleOutline,
        mdiMail, mdiClipboardArrowRightOutline, mdiTagHeartOutline, mdiHomeOutline
    } from '@mdi/js';
    import {Svg} from '@smui/common/elements';

    let menu: MenuComponentDev;

    const handleLogout = async () => {
        await auth.signOut();
        goto(`/`);

    }

    const handleMenu = ((dest:String) => {
        goto(dest);
    });

    const menuItems = [
        { 
            text: 'Home', 
            icon: mdiHomeOutline, 
            path: '/',
            active: true,
            auth: true,
            role: null
        },        { 
            text: 'Rules', 
            icon: mdiInformationOutline, 
            path: '/about',
            active: true,
            auth: true,
            role: null
        },
        { 
            text: 'Register', 
            icon: mdiAccountPlusOutline, 
            path: '/registration',
            active: true,
            auth: false,
            role: null
        },
        {
            text: 'Profile',
            icon: mdiAccountCircleOutline,
            path: '/profile',
            active: true,
            auth: true,
            role: null
        },
        {
            text: "Messages",
            icon: mdiMail,
            path: '/inbox',
            active: false,
            auth: true,
            role: null
        }
    ];    
   
    //let topAppBar: TopAppBarComponentDev;    
    let topAppBar: TopAppBar;    

    let darkTheme: boolean | undefined = undefined;

    onMount(() => {
        darkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const analytics = getAnalytics(app);
        logEvent(analytics, 'MPA_page_rendered');
    })
</script>

<svelte:head>
    <!-- SMUI Styles -->
    {#if darkTheme === undefined}
        <link rel="stylesheet" href="/smui.css" media="(prefers-color-scheme: light)" />
        <link rel="stylesheet" href="/smui-dark.css" media="screen and (prefers-color-scheme: dark)"/>
    {:else if darkTheme}
        <link rel="stylesheet" href="/smui.css" />
        <link rel="stylesheet" href="/smui-dark.css" media="screen"/>
    {:else}
        <link rel="stylesheet" href="/smui.css" />
    {/if}
    <!--
    <link rel="stylesheet" href="smui-typography.css" />
    -->
</svelte:head>

<TopAppBar bind:this={topAppBar} variant="fixed">
    <Row>
        <Section>
        <IconButton class="mainMenuIcon" aria-label="menu" on:click={() => menu.setOpen(true)} variant="raised" title="menu">
            <Icon component={Svg} viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiMenu} />
            </Icon>
        </IconButton>
        {#if $auth.known}
            <Menu bind:this={menu} style="margin: 10px;">
                <List>
                    {#each menuItems.filter(m => {
                            return (
                                m.active && ((!m.auth && !$auth.user) || (m.auth && ($auth.user && (!m.role || (m.role === $userInfo.type)))))
                            )
                        }) as menuItem 
                    }
                        <Item on:SMUI:action={() => (handleMenu(menuItem.path))}>
                            <IconButton style="color: var(--mdc-theme-secondary);" aria-label={menuItem.text} on:click={() => goto(menuItem.path)} variant="raised" title={menuItem.text}>
                                <Icon component={Svg} viewBox="0 0 24 24">
                                    <path fill="currentColor" d={menuItem.icon} />
                                </Icon>
                            </IconButton>                                        
                            <Text>{menuItem.text}</Text>
                        </Item>                    
                    {/each}
                </List>
            </Menu>        
        {/if}
        <Title ><a href="/" style="text-decoration: none; color: var(--mdc-theme-secondary);">AckermanPla.net Football Pool</a></Title>
        </Section>
        <Section align="end" toolbar>
            {#if $auth.known}
                {#if $auth.user}
                    Welcome{ $auth.user.displayName?`, ${$auth.user.displayName}`:``}
                    <IconButton color="secondary" aria-label="Logout" on:click={handleLogout} variant="raised" title="Logout">
                        <Icon component={Svg} viewBox="0 0 24 24">
                            <path fill="currentColor" d={mdiLogout} />
                        </Icon>
                    </IconButton>
                    <IconButton color="secondary" aria-label="Profile" on:click={() => goto(`/profile`)} variant="raised" title="Profile">
                        <Icon component={Svg} viewBox="0 0 24 24">
                            <path fill="currentColor" d={mdiAccount} />
                        </Icon>
                    </IconButton>
                {:else}
                    <IconButton color="secondary" aria-label="Login" on:click={() => goto(`/login`)} variant="raised" title="Login">
                        <Icon component={Svg} viewBox="0 0 24 24">
                            <path fill="currentColor" d={mdiLogin} />
                        </Icon>
                    </IconButton>
                {/if}     
                <IconButton on:click={()=> (darkTheme = !darkTheme)} title={darkTheme ? 'Lights On' : 'Lights Off'}>
                    <Icon component={Svg} viewBox="0 0 24 24">
                        <path fill="currentColor" d={darkTheme ? mdiWeatherSunny : mdiWeatherNight} />
                    </Icon>
                </IconButton>
            {:else}
                Checking auth status &hellip;
            {/if}                    
        </Section>
    </Row>
</TopAppBar>
{#await loadAll([userInfo]) }
    <p></p>
{:then}
    <AutoAdjust {topAppBar}>
        <div id="container">
            <slot></slot>
        </div>
        <hr>
        <footer>&copy 2008-{new Date().getFullYear()} AckermanPla.net</footer>
    </AutoAdjust>        
{/await}

<style>
    :root {
        --mdc-typography-headline6-font-family: Quicksand;
        --mdc-theme-primary: #283E67;
        --mdc-theme-secondary: #7CFC00;
        --mdc-theme-tertiary: #ffbf1f;
    }   
    div#container {
        padding: 10px;
    }
    footer {
        padding: 10px;
    }
</style>
            

